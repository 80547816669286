<template>
    <div class="basic_data_edit">
        <div class="data_content scrollbar">
            <!-- 按钮 -->
            <div class="botton_box">
                <span class="menu_title">管理制度</span>
                <div class="button_list">
                    <sn-button
                        :snButton="snButton"
                        :class="{ showButton: !saveButton }"
                        @handleChange="handleChange"
                    ></sn-button>
                </div>
            </div>
            <!-- 表单 -->
            <el-form
                ref="formdata"
                :inline="true"
                :model="formdata.dataDic"
                :rules="rules"
                class="demo-form-inline form_row"
                size="small"
                label-width="200px"
            >
                <el-form-item label="名称" prop="MC" :style="{ width: '80%' }">
                    <el-input
                        v-model="formdata.dataDic.MC"
                        :disabled="!isEdit"
                        placeholder="请填写名称"
                    >
                    </el-input>
                </el-form-item>
                <el-form-item label="监测时间" :style="{ width: '80%' }">
                    <el-date-picker
                        v-model="formdata.dataDic.JCSJ"
                        type="date"
                        :disabled="!isEdit"
                        value-format="yyyy-MM-dd"
                        placeholder="请选择监测时间"
                    >
                    </el-date-picker>
                </el-form-item>
                <el-form-item
                    label="管理制度完善程度"
                    :style="{ width: '80%' }"
                >
                    <el-select
                        v-model="formdata.dataDic.GLZDWSCD"
                        :disabled="!isEdit"
                    >
                        <el-option
                            v-for="(item, index) in enumObj.GLZDWSCD || []"
                            :key="`${index}`"
                            :value="item.CODE"
                            :label="item.LABEL"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="管理制度附件" :style="{ width: '80%' }">
                    <basic-upload
                        ref="uploadFile"
                        :isEdit="isEdit"
                        :itemId="itemId"
                        :metaData.sync="formdata.metaJson"
                        basicType="document"
                        :disabled="!isEdit"
                    ></basic-upload>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
import basicUpload from "@comp/basic_upload";
import infoMixin from "../0_com_js/info_mixin.js";
export default {
    name: "glzd",
    components: {
        basicUpload,
    },
    mixins: [infoMixin],
    data() {
        return {
            isEdit: false,
            saveButton: true,
            formdata: {
                dataDic: {
                    glycbtid: this.heritageId,
                    MC: null,
                    JCSJ: null,
                    GLZDWSCD: null,
                },
                fileids: "",
                itemCode: "205",
                metaJson: [],
            },
            rules: {
                MC: [
                    {
                        required: true,
                        message: "请填写名称",
                        trigger: ["blur", "change"],
                    },
                ],
            },
        };
    },
    methods: {},
};
</script>

<style></style>
